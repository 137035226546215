import { Dispatch, SetStateAction } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button } from 'src/components/ui/button'
import { DialogClose } from 'src/components/ui/dialog'
import { IOrder } from 'src/_models/order.model'
import OrderDocumentStatus from 'src/components/order/document-status'
import OrderSignatureGuaranteeView from 'src/components/order/signature-guarantee-view'
import TotalPayableAmount from 'src/components/order/total-payable-amount'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import OrderSignatureCompletedView from 'src/components/order/signature-completed-view'

const SupplierOrderCompleted = ({
  order,
  goNext,
  setShowOrderStatusModal,
}: {
  order: IOrder
  goNext?: () => void
  setShowOrderStatusModal: Dispatch<SetStateAction<boolean>>
}) => {
  const queryClient = useQueryClient()
  const orderCompleteMutation = useMutation({
    mutationKey: ['orderCompleteMutation'],
    mutationFn: ({ orderId }: { orderId: number }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/supplier/order-complete/${orderId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const orderComplete = () => {
    orderCompleteMutation.mutate(
      { orderId: order.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          toast({
            title: 'Success',
            description: 'Order completed.',
          })
        },
        onError: (e) => {
          const axiosError = e as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: axiosError?.response?.data?.message ?? 'Error',
            description: 'Order completed.',
          })
        },
      },
    )
  }

  return (
    <>
      <div className="flex max-h-[calc(90vh-514px)] flex-col  overflow-y-scroll px-[30px] ">
        <OrderDocumentStatus order={order} />
        <div className="mt-5 flex flex-row justify-between">
          <OrderSignatureCompletedView
            order={order}
            // shouldnt this be BUYER_DELIVERY_CONFIRMATION?
            type="SUPPLIER_DELIVERY_CONFIRMATION"
            title="Delivery Confirmation Sign."
            orderGuaranteeText={order.supplierDeliveryConfirmationText}
            orderGuaranteeMediaKey={order.supplierDeliveryConfirmationMedia?.fileKey}
          />
          <OrderSignatureCompletedView
            order={order}
            // shouldnt this be BUYER_PRODUCT_CONFIRMATION?
            type="SUPPLIER_PRODUCT_CONFIRMATION"
            title="Product Confirmation Sign."
            orderGuaranteeText={order.supplierProductConfirmationText}
            orderGuaranteeMediaKey={order.supplierProductConfirmationMedia?.fileKey}
          />
        </div>
      </div>
      <div className="mt-5 h-[136px] px-[30px] pb-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <TotalPayableAmount order={order} />
        <div className="flex gap-5">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            disabled={orderCompleteMutation.isLoading || !order.adminOrderCompletedApprovalAt}
            onClick={orderComplete}
          >
            Order Completed
          </Button>
        </div>
      </div>
    </>
  )
}

export default SupplierOrderCompleted
